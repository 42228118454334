// extracted by mini-css-extract-plugin
export var hero = "index-module--hero--36Skz";
export var heroInner = "index-module--heroInner--3NSoC";
export var heroHeading = "index-module--heroHeading--14-tB";
export var highlight = "index-module--highlight--1PfF-";
export var link = "index-module--link--1CAXf";
export var heroCopy = "index-module--heroCopy--ERCBA";
export var heroCta = "index-module--heroCta--21ISf";
export var sectionTitle = "index-module--sectionTitle--11G-L";
export var avatarBox = "index-module--avatarBox--3gzUd";
export var avatar = "index-module--avatar--ykn0h";